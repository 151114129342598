
input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 12px;
  height: 12px;
  -webkit-appearance: none;
  appearance: none;
  background: #2196F3;
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  pointer-events: all;
  width: 12px;
  height: 12px;
  appearance: none;
  background: #2196F3;
  border-radius: 50%;
}
