@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #241f1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Style power inputfield in filter component */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* Style scrollbar in app */
body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background: background-color;
}

body::-webkit-scrollbar-thumb {
  background-color: #2563eb;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #3b82f6;
}

section {
  position: sticky;
  top: 20px;
  max-height: 100vh;
}
/* Style scrollbar in filter component */
.filter-container {
  padding-right: 8px;
  overflow-y: auto;
  max-height: calc(100vh - 20px - 4rem);
}

.filter-container::-webkit-scrollbar {
  width: 5px;
}

.filter-container::-webkit-scrollbar-track {
  background: background-color;
}

.filter-container::-webkit-scrollbar-thumb {
  background-color: #2563eb;
  border-radius: 10px;
}

.filter-container::-webkit-scrollbar-thumb:hover {
  background: #3b82f6;
}
